import { API } from "./api.base.js";

export default {
  async getRecording() {
    try {
      const response = await API.get(
        "/api/rest/v2/rinkelrouting/rinkelrecording"
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  },

  async getRecordingLink(id) {
    try {
      const response = await API.get(
        `/api/rest/v2/rinkelrouting/rinkelrecording/link/${id}`
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  },
  async getAllRoutings() {
    try {
      const response = await API.get("/api/rest/v2/rinkelrouting");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateRouting(id, content) {
    try {
      const response = await API.put(
        `/api/rest/v2/rinkelrouting/${id}`,
        content
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async createRouting(data) {
    try {
      const response = await API.post(`/api/rest/v2/rinkelrouting`, data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async deleteRouting(id) {
    try {
      const response = await API.delete(`/api/rest/v2/rinkelrouting/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
