<template>
  <div>
    <Menu />
    <v-container>
      <!-- Knop voor het openen van de modal -->
      <v-btn color="primary" @click="openModal()" class="mb-4">
        Aanmaken
      </v-btn>

      <!-- Tabel met bestaande routings -->
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Naam</th>
              <th class="text-left">Tijdvakken</th>
              <th class="text-left">Omschrijving</th>
              <th class="text-left">Acties</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in routings" :key="item.id">
              <td>{{ item.name }}</td>
              <td>
                <ul>
                  <li v-for="slot in item.timeSlots" :key="slot.id">
                    {{ slot.dayOfWeek }}: {{ slot.startTime }} -
                    {{ slot.endTime }} ({{ slot.recordingName }})

                    <v-btn
                      small
                      :color="playingTimeSlot === slot.id ? 'red' : 'warning'"
                      @click="fetchRoutingsLink(slot.id)"
                    >
                      {{ playingTimeSlot === slot.id ? "Stoppen" : "Afspelen" }}
                    </v-btn>
                  </li>
                </ul>
              </td>
              <td>{{ item.description }}</td>
              <td>
                <div style="display: flex; gap: 10px">
                  <v-btn small color="info" @click="openModal(item)">
                    Bewerken
                  </v-btn>

                  <v-btn small color="error" @click="deleteRouting(item.id)">
                    Verwijderen
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>

    <!-- Modal voor aanmaken/bewerken routing -->
    <v-dialog v-model="modalOpen" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            isEditing ? "Routing bewerken" : "Nieuwe routing"
          }}</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="form.name"
              label="Naam"
              required
            ></v-text-field>
            <v-textarea
              v-model="form.description"
              label="Omschrijving"
            ></v-textarea>

            <!-- Tijdvakken toevoegen -->
            <div v-for="(slot, index) in form.timeSlots" :key="index">
              <v-row>
                <v-col cols="4">
                  <v-select
                    v-model="slot.dayOfWeek"
                    :items="weekDays"
                    label="Dag van de week"
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="slot.startTime"
                    label="Starttijd"
                    type="time"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="slot.endTime"
                    label="Eindtijd"
                    type="time"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn small color="error" @click="removeTimeSlot(index)"
                    >X</v-btn
                  >
                </v-col>
              </v-row>

              <v-select
                v-model="slot.selectedRecording"
                :items="recordings"
                item-value="id"
                item-text="name"
                label="Selecteer opname"
              ></v-select>
            </div>

            <v-btn color="success" small @click="addTimeSlot"
              >Tijdvak toevoegen</v-btn
            >
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="grey" @click="closeModal">Annuleren</v-btn>
          <v-btn color="primary" @click="saveRouting">Opslaan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar voor succesberichten -->
    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="white" text @click="snackbar.show = false">Sluiten</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import Menu from "../dashboard/Menu.vue";
import apiRinkelRouting from "../api/apiRinkelRouting";

export default {
  components: {
    Menu,
  },
  data() {
    return {
      routings: [],
      recordings: [],
      weekDays: [
        "maandag",
        "dinsdag",
        "woensdag",
        "donderdag",
        "vrijdag",
        "zaterdag",
        "zondag",
      ],
      form: {
        id: null,
        name: "",
        description: "",
        timeSlots: [],
      },
      audioUrl: "",
      audio: null, // ✅ Houd het audio-object bij
      playingTimeSlot: null, //
      routingslink: {},
      modalOpen: false,
      isEditing: false,
      snackbar: {
        show: false,
        text: "",
        timeout: 3000,
      },
    };
  },

  async mounted() {
    await this.fetchRoutings();
    await this.fetchRecordings();
  },

  methods: {
    // Haal alle bestaande routings op
    async fetchRoutings() {
      try {
        const data = await apiRinkelRouting.getAllRoutings();
        this.routings = data;
      } catch (error) {
        console.error("Fout bij ophalen routings:", error);
      }
    },

    async fetchRoutingsLink(timeSlotId) {
      try {
        if (this.audio && !this.audio.paused) {
          this.audio.pause();
          this.audio.currentTime = 0;
          this.playingTimeSlot = null;
          return;
        }
        const response = await apiRinkelRouting.getRecordingLink(timeSlotId);
        if (response.data?.data?.data?.url) {
          const recordingUrl = response.data.data.data.url;
          this.audio = new Audio(recordingUrl);
          this.audio
            .play()
            .then(() => {
              this.playingTimeSlot = timeSlotId;
            })
            .catch((error) => {
              console.error("Fout bij afspelen van audio:", error);
              this.$swal.fire("Fout", "Kan de opname niet afspelen", "error");
            });
          this.audio.onended = () => {
            this.playingTimeSlot = null;
          };
        } else {
          console.error("Geen geldige stream URL gevonden:", response.data);
          this.$swal.fire("Fout", "Geen geldige opname gevonden", "error");
        }
      } catch (error) {
        console.error("Fout bij ophalen streamlink:", error);
        this.$swal.fire("Fout", "Kon de opname niet ophalen", "error");
      }
    },

    // Haal alle beschikbare opnames op
    async fetchRecordings() {
      try {
        const response = await apiRinkelRouting.getRecording();
        if (response.data && Array.isArray(response.data.data)) {
          this.recordings = response.data.data.map((recording) => ({
            id: recording.id,
            name: recording.name,
          }));
        }
      } catch (error) {
        console.error("Fout bij ophalen recordings:", error);
      }
    },

    // Open modal voor aanmaken of bewerken
    openModal(routing = null) {
      if (routing) {
        this.form = {
          id: routing.id,
          name: routing.name,
          description: routing.description,
          timeSlots: routing.timeSlots.map((slot) => ({
            id: slot.id,
            dayOfWeek: slot.dayOfWeek,
            startTime: slot.startTime,
            endTime: slot.endTime,
            selectedRecording: slot.voiceId,
          })),
        };
        this.isEditing = true;
      } else {
        this.isEditing = false;
      }
      this.modalOpen = true;
    },

    // Modal sluiten en formulier resetten
    closeModal() {
      this.modalOpen = false;
    },

    // Tijdvak toevoegen
    addTimeSlot() {
      this.form.timeSlots.push({
        dayOfWeek: "",
        startTime: "",
        endTime: "",
        selectedRecording: "",
      });
    },

    // Tijdvak verwijderen
    removeTimeSlot(index) {
      this.form.timeSlots.splice(index, 1);
    },

    // Opslaan
    async saveRouting() {
      try {
        const payload = {
          name: this.form.name,
          description: this.form.description,
          timeSlots: this.form.timeSlots.map((slot) => ({
            dayOfWeek: slot.dayOfWeek,
            startTime: slot.startTime,
            endTime: slot.endTime,
            voiceId: slot.selectedRecording,
            recordingName:
              this.recordings.find((r) => r.id === slot.selectedRecording)
                ?.name || "",
          })),
        };

        if (this.isEditing) {
          await apiRinkelRouting.updateRouting(this.form.id, payload);
        } else {
          await apiRinkelRouting.createRouting(payload);
        }

        this.fetchRoutings();
        this.closeModal();
      } catch (error) {
        console.error("Fout bij opslaan routing:", error);
      }
    },

    async deleteRouting(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je de routing wil verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        try {
          await apiRinkelRouting.deleteRouting(id);
          await this.fetchRoutings();
          await this.fetchRecordings();
          this.$swal.fire(
            "Verwijderd",
            "De routing is succesvol verwijderd",
            "success"
          );
        } catch (error) {
          console.error("Fout bij het verwijderen van de routing:", error);
        }
      }
    },
  },
};
</script>
