<template>
  <div>
    <Menu />
    <v-container class="employee-container" fluid>
      <v-row>
        <AddEmployee
          v-if="showAddEmployee"
          @employee-added="handleEmployeeAdded"
        />
        <div
          style="margin-top: 10px; margin-left: 180px"
          v-if="!showAddEmployee"
        >
          <v-btn color="primary" @click="showAddEmployee = !showAddEmployee">
            Medewerkers toevoegen
          </v-btn>
        </div>
        <v-col cols="12">
          <v-container v-if="!showAddEmployee">
            <v-card>
              <v-card-title>
                Team
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Opzoeken"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-row>
                <v-col v-for="letter in letters" :key="letter" cols="auto">
                  <v-btn
                    :color="selectedLetter === letter ? 'primary' : ''"
                    @click="selectLetter(letter)"
                  >
                    {{ letter }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-container>
                <v-row>
                  <v-col
                    v-for="employee in filteredEmployees"
                    :key="employee.id"
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-card>
                      <v-img
                        :src="
                          employee.images[0]
                            ? `https://digitalsignbackend.waldent.nl/static/uploads/${employee.images[0].filename}`
                            : require('@/assets/logo.jpg')
                        "
                        height="240px"
                        contain
                        width="350"
                      ></v-img>
                      <v-card-title>{{ employee.name }}</v-card-title>
                      <v-card-text>
                        <div>{{ employee.title }}</div>
                      </v-card-text>
                      <v-card-actions class="button-container">
                        <v-spacer></v-spacer>

                        <v-btn
                          color="black"
                          icon
                          @click="openEditDialog(employee)"
                        >
                          <v-icon>mdi-pencil</v-icon>
                          <!-- Bewerken -->
                        </v-btn>

                        <v-btn
                          color="blue"
                          icon
                          @click="toggleWorkdaysVisibility(employee.id)"
                        >
                          <v-icon>mdi-calendar-check</v-icon>
                          <!-- Werkdagen bekijken -->
                        </v-btn>

                        <v-btn
                          color="red"
                          icon
                          @click="deleteEmployee(employee.id)"
                        >
                          <v-icon>mdi-trash-can</v-icon>
                          <!-- Verwijderen -->
                        </v-btn>

                        <v-spacer></v-spacer>
                      </v-card-actions>

                      <div v-if="employee.showWorkdays">
                        <div v-for="(workday, index) in workdays" :key="index">
                          {{ workday }}
                          <input
                            type="checkbox"
                            :checked="employee.workdays.includes(workday)"
                            @change="
                              toggleWorkdays(
                                employee.id,
                                workday,
                                $event.target.checked
                              )
                            "
                          />
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <!-- Modal -->
    <v-dialog v-model="editDialog" max-width="500px">
      <v-card>
        <v-card-title>Bewerk medewerker</v-card-title>
        <v-card-text>
          <v-text-field
            label="Naam"
            v-model="selectedEmployee.editedName"
            dense
            outlined
          />
          <v-text-field
            label="Titel"
            v-model="selectedEmployee.editedTitle"
            dense
            outlined
          />
        </v-card-text>

        <v-card-actions class="modal-actions">
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="editDialog = false"
            >Annuleren</v-btn
          >
          <span class="v-spacer" style="margin-right: 10px"></span>
          <v-btn color="primary" @click="saveEdit(selectedEmployee)"
            >Opslaan</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiTeam from "../api/apiTeam";
import AddEmployee from "../dashboard/AddEmployee";
import Menu from "./Menu.vue";

export default {
  components: {
    AddEmployee,
    Menu,
  },
  data: () => ({
    showAddEmployee: false,
    showWorkdays: false,
    editDialog: false,
    selectedEmployee: {},
    workdays: ["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag"],
    employees: [],
    search: "",
    selectedLetter: "",
    letters: [..."ABCDEFGHIJKLMNOPQRSTUVWXYZ"],
  }),

  async mounted() {
    await this.fetchEmployees();
  },

  computed: {
    filteredEmployees() {
      if (this.selectedLetter) {
        return this.employees.filter((employee) => {
          return employee.name.charAt(0).toUpperCase() === this.selectedLetter;
        });
      }
      return this.employees;
    },
  },

  methods: {
    async fetchEmployees() {
      try {
        const data = await apiTeam.getAllAdminTeams();
        this.employees = data.map((employee) => ({
          ...employee,
          showWorkdays: false,
          workdays: employee.workdays.map((w) => w.name),
          selectedWorkdays: new Array(this.workdays.length).fill(false),
        }));
      } catch (error) {
        console.error("Fout bij ophalen medewerkers:", error);
      }
    },

    openEditDialog(employee) {
      this.selectedEmployee = {
        ...employee,
        editedName: employee.name,
        editedTitle: employee.title,
      };
      this.editDialog = true;
    },

    async saveEdit(employee) {
      try {
        await apiTeam.updateTeam(employee.id, {
          name: employee.editedName,
          title: employee.editedTitle,
        });

        const index = this.employees.findIndex((e) => e.id === employee.id);
        if (index !== -1) {
          this.employees[index].name = employee.editedName;
          this.employees[index].title = employee.editedTitle;
        }

        this.$swal.fire("Opgeslagen!", "", "success");
        this.editDialog = false;
      } catch (err) {
        console.error("Fout bij opslaan:", err);
        this.$swal.fire("Er ging iets mis bij het opslaan", "", "error");
      }
    },

    async handleEmployeeAdded() {
      this.showAddEmployee = false;
      await this.fetchEmployees();
    },

    toggleWorkdaysVisibility(employeeId) {
      const employee = this.employees.find((e) => e.id === employeeId);
      if (employee) {
        employee.showWorkdays = !employee.showWorkdays;
      }
    },

    toggleWorkdays(employeeId, workdayName, selected) {
      const employee = this.employees.find((e) => e.id === employeeId);
      if (employee) {
        if (selected) {
          employee.workdays.push(workdayName);
        } else {
          const index = employee.workdays.indexOf(workdayName);
          if (index !== -1) {
            employee.workdays.splice(index, 1);
          }
        }
        this.updateEmployee(employeeId);
      }
    },

    async updateEmployee(employeeId) {
      const employee = this.employees.find((e) => e.id === employeeId);
      if (employee) {
        try {
          await apiTeam.updateTeam(employeeId, { workdays: employee.workdays });
        } catch (error) {
          console.error("Fout bij updaten werkdagen:", error);
        }
      }
    },

    async deleteEmployee(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je de medewerker wil verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        try {
          await apiTeam.deleteTeam(id);
          await this.fetchEmployees();
          this.$swal.fire(
            "Verwijderd",
            "De medewerker is verwijderd",
            "success"
          );
        } catch (error) {
          console.error("Fout bij verwijderen:", error);
        }
      }
    },

    selectLetter(letter) {
      this.selectedLetter = letter;
    },
  },
};
</script>

<style scoped>
.employee-container {
  max-height: 1000px;
}
.button-container {
  display: flex;
  justify-content: center;
  gap: 15px; /* Ruimte tussen knoppen */
}
</style>
