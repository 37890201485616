<template>
  <v-toolbar color="blue" elevation="4" flat outlined>
    <span style="margin-right: 3px">
      <v-btn @click="$router.push('/Dashboard')"> Dashboard </v-btn>
    </span>

    <!-- Menu-knop direct naast Home-knop -->
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark v-bind="attrs" v-on="on"> Menu </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in menuItems"
          :key="index"
          @click="closeMenu"
        >
          <v-list-item-title>
            <router-link :to="item.route">
              {{ item.text }}
            </router-link>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Push alles erna naar rechts -->
    <v-spacer></v-spacer>

    <!-- Instellingen knop aan de rechterkant -->
    <Settings />
  </v-toolbar>
</template>

<script>
import Settings from "./Settings.vue";
export default {
  components: {
    Settings,
  },
  data() {
    return {
      // Hier voeg je je menu-items toe
      menuItems: [
        { text: "Home", route: "/Dashboard" },
        { text: "Content beheren", route: "/Content" },
        { text: "Medewerkersbeheren", route: "/Teambeheren" },
        { text: "QRcode maken", route: "/contQRgeneratoract" },
        {
          text: "Diensten teksten bewerken",
          route: "/WebsiteBehandelingenBeheren",
        },
        { text: "Webstite teksten bewerken", route: "/WebsiteContentBeheren" },
        { text: "Folders beheren", route: "/Folders" },
      ],
      menu: false,
    };
  },
  methods: {
    closeMenu() {
      this.menu = false;
    },
  },
};
</script>
