import { render, staticRenderFns } from "./AddEmployee.vue?vue&type=template&id=27d4a4ac"
import script from "./AddEmployee.vue?vue&type=script&lang=js"
export * from "./AddEmployee.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports