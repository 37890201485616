var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Menu'),_c('v-container',{staticClass:"employee-container",attrs:{"fluid":""}},[_c('v-row',[(_vm.showAddEmployee)?_c('AddEmployee',{on:{"employee-added":_vm.handleEmployeeAdded}}):_vm._e(),(!_vm.showAddEmployee)?_c('div',{staticStyle:{"margin-top":"10px","margin-left":"180px"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.showAddEmployee = !_vm.showAddEmployee}}},[_vm._v(" Medewerkers toevoegen ")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[(!_vm.showAddEmployee)?_c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v(" Team "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Opzoeken","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-row',_vm._l((_vm.letters),function(letter){return _c('v-col',{key:letter,attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":_vm.selectedLetter === letter ? 'primary' : ''},on:{"click":function($event){return _vm.selectLetter(letter)}}},[_vm._v(" "+_vm._s(letter)+" ")])],1)}),1),_c('v-container',[_c('v-row',_vm._l((_vm.filteredEmployees),function(employee){return _c('v-col',{key:employee.id,attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-card',[_c('v-img',{attrs:{"src":employee.images[0]
                          ? `https://digitalsignbackend.waldent.nl/static/uploads/${employee.images[0].filename}`
                          : require('@/assets/logo.jpg'),"height":"240px","contain":"","width":"350"}}),_c('v-card-title',[_vm._v(_vm._s(employee.name))]),_c('v-card-text',[_c('div',[_vm._v(_vm._s(employee.title))])]),_c('v-card-actions',{staticClass:"button-container"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"black","icon":""},on:{"click":function($event){return _vm.openEditDialog(employee)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"color":"blue","icon":""},on:{"click":function($event){return _vm.toggleWorkdaysVisibility(employee.id)}}},[_c('v-icon',[_vm._v("mdi-calendar-check")])],1),_c('v-btn',{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.deleteEmployee(employee.id)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1),_c('v-spacer')],1),(employee.showWorkdays)?_c('div',_vm._l((_vm.workdays),function(workday,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(workday)+" "),_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":employee.workdays.includes(workday)},on:{"change":function($event){return _vm.toggleWorkdays(
                              employee.id,
                              workday,
                              $event.target.checked
                            )}}})])}),0):_vm._e()],1)],1)}),1)],1)],1)],1):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Bewerk medewerker")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Naam","dense":"","outlined":""},model:{value:(_vm.selectedEmployee.editedName),callback:function ($$v) {_vm.$set(_vm.selectedEmployee, "editedName", $$v)},expression:"selectedEmployee.editedName"}}),_c('v-text-field',{attrs:{"label":"Titel","dense":"","outlined":""},model:{value:(_vm.selectedEmployee.editedTitle),callback:function ($$v) {_vm.$set(_vm.selectedEmployee, "editedTitle", $$v)},expression:"selectedEmployee.editedTitle"}})],1),_c('v-card-actions',{staticClass:"modal-actions"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.editDialog = false}}},[_vm._v("Annuleren")]),_c('span',{staticClass:"v-spacer",staticStyle:{"margin-right":"10px"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.saveEdit(_vm.selectedEmployee)}}},[_vm._v("Opslaan")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }