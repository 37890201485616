<template>
  <div>
    <Menu />
    <v-container>
      <div style="margin-bottom: 10px">
        <v-btn color="primary" @click="openModal()" class="mb-4">
          Persoon toevoegen
        </v-btn>
      </div>

      <v-row>
        <h5>Personen tabel</h5>
        <v-col cols="12">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Naam</th>
                  <th class="text-left">Telefoonnummer</th>
                  <th class="text-left">Automatisch ontvangen</th>
                  <th class="text-left">Acties</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in persons" :key="item.id">
                  <td>{{ item.name || "Onbekend" }}</td>
                  <td>{{ item.phonenumber || "Onbekend" }}</td>
                  <td>{{ item.deliver ? "Ja" : "Nee" }}</td>
                  <td>
                    <div style="display: flex; gap: 10px">
                      <v-btn small color="info" @click="openModal(item)">
                        Bewerken
                      </v-btn>
                      <v-btn
                        small
                        color="error"
                        @click="deleteRouting(item.id)"
                      >
                        Verwijderen
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <div style="padding-top: 50px">
          <h5>Voicemails tabel (Deze week ingesproken)</h5>
          <v-col cols="12">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Datum</th>
                    <th class="text-left">Type</th>
                    <th class="text-left">Telefoonnummer</th>
                    <th class="text-left">Aantal seconden</th>
                    <th class="text-left">Voicemail ingesproken</th>
                    <th class="text-left">Acties</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in voicemails" :key="item.id">
                    <td>{{ formatDate(item.date) }}</td>
                    <td>{{ item.status }}</td>
                    <td>
                      {{
                        item.externalNumber
                          ? item.externalNumber.e164
                          : "Onbekend"
                      }}
                    </td>
                    <td>{{ item.duration }}</td>
                    <td>
                      <a v-if="item.voicemail && item.voicemail.playUrl"
                        >Voice ingesproken</a
                      >
                      <span v-else>Geen voicemail</span>
                    </td>
                    <td>
                      <div style="display: flex; gap: 10px">
                        <v-btn
                          small
                          :color="
                            playingTimeSlot === item.voicemail.id
                              ? 'red'
                              : 'warning'
                          "
                          @click="fetchStreamingLink(item.voicemail.id)"
                        >
                          {{
                            playingTimeSlot === item.voicemail.id
                              ? "Stoppen"
                              : "Afspelen"
                          }}
                        </v-btn>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </div>
      </v-row>
    </v-container>

    <!-- Modal voor aanmaken/bewerken routing -->
    <v-dialog v-model="modalOpen" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            isEditing ? "Routing bewerken" : "Nieuwe routing"
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="form.name"
              label="Naam"
              required
            ></v-text-field>
            <v-text-field
              v-model="form.phonenumber"
              label="Telefoonnummer"
            ></v-text-field>
            <v-switch
              v-model="form.deliver"
              :label="form.deliver ? 'Ja' : 'Nee'"
              @change="updateSwitch"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey" @click="closeModal">Annuleren</v-btn>
          <v-btn color="primary" @click="saveRouting">Opslaan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar voor succesberichten -->
    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="white" text @click="snackbar.show = false">Sluiten</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import Menu from "../dashboard/Menu.vue";
import apiRinkelVoicemail from "../api/apiRinkelVoicemail";

export default {
  components: {
    Menu,
  },
  data() {
    return {
      voicemails: [],
      persons: [],
      selectedVoicemail: null, // Hier slaan we de geselecteerde voicemail op
      voicemailModalOpen: false, // Dit opent/sluit de modal
      audioUrl: "",
      audio: null, // ✅ Houd het audio-object bij
      playingTimeSlot: null, //
      form: {
        id: null,
        name: "",
        phonenumber: "",
        deliver: null,
      },
      modalOpen: false,
      isEditing: false,
      snackbar: {
        show: false,
        text: "",
        timeout: 3000,
      },
    };
  },

  async mounted() {
    await this.fetchVoicemails();
    await this.fetchRoutings();
  },

  methods: {
    async deleteRouting(id) {
      try {
        await apiRinkelVoicemail.deleteRouting(id); // Zorg dat de juiste API-aanroep wordt gedaan
        this.fetchRoutings(); // Vernieuw de lijst na verwijderen
        this.snackbar = {
          show: true,
          text: "Routing succesvol verwijderd",
          timeout: 3000,
        };
      } catch (error) {
        console.error("Fout bij verwijderen routing:", error);
        this.snackbar = {
          show: true,
          text: "Fout bij verwijderen routing",
          timeout: 3000,
        };
      }
    },

    async fetchStreamingLink(id) {
      try {
        if (this.audio && !this.audio.paused) {
          this.audio.pause();
          this.audio.currentTime = 0;
          this.playingTimeSlot = null;
          return;
        }
        const response = await apiRinkelVoicemail.getvoicemailStream(id);
        const recordingUrl = response.data?.url || response.data.data?.url;

        if (!recordingUrl) {
          console.error("Geen geldige stream URL gevonden:", response.data);
          this.$swal.fire("Fout", "Geen geldige opname gevonden", "error");
          return;
        }
        this.audio = new Audio(recordingUrl);
        this.audio
          .play()
          .then(() => {
            this.playingTimeSlot = id;
          })
          .catch((error) => {
            console.error("Fout bij afspelen van audio:", error);
            this.$swal.fire("Fout", "Kan de opname niet afspelen", "error");
          });
        this.audio.onended = () => {
          this.playingTimeSlot = null;
        };
      } catch (error) {
        console.error("Fout bij ophalen streamlink:", error);
        this.$swal.fire("Fout", "Kon de opname niet ophalen", "error");
      }
    },
    async fetchVoicemails() {
      try {
        const response = await apiRinkelVoicemail.getVoicemails();
        this.voicemails = response.data.data;
      } catch (error) {
        console.error("Fout bij ophalen voicemails:", error);
      }
    },

    async fetchRoutings() {
      try {
        const response = await apiRinkelVoicemail.getRinkelPersons();
        this.persons = response.data;
      } catch (error) {
        console.error("Fout bij ophalen routings:", error);
      }
    },

    openModal(item = null) {
      if (item) {
        this.form = { ...item };
        this.isEditing = true;
      } else {
        this.form = { id: null, name: "", phonenumber: "", deliver: null };
        this.isEditing = false;
      }
      this.modalOpen = true;
    },

    closeModal() {
      this.modalOpen = false;
    },

    updateSwitch() {
      // Dit zorgt ervoor dat deliver altijd een boolean is
      this.form.deliver = !!this.form.deliver;
    },
    async saveRouting() {
      try {
        this.form.deliver = !!this.form.deliver;

        if (this.isEditing) {
          await apiRinkelVoicemail.updateRouting(this.form.id, this.form);
        } else {
          await apiRinkelVoicemail.createRouting(this.form);
        }

        this.fetchRoutings();
        this.closeModal();
      } catch (error) {
        console.error("Fout bij opslaan routing:", error);
      }
    },

    async deleteVoicemail(id) {
      try {
        await apiRinkelVoicemail.deleteVoicemail(id);
        this.fetchVoicemails();
      } catch (error) {
        console.error("Fout bij verwijderen voicemail:", error);
      }
    },

    formatDate(date) {
      return new Date(date).toLocaleString();
    },
  },
};
</script>
