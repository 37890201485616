import { API } from "./api.base.js";

export default {
  async getRinkelPersons() {
    try {
      const response = await API.get("/api/rest/v2/rinkelvoicemail");
      return response;
    } catch (error) {
      console.error(error);
    }
  },

  async getvoicemailStream(id) {
    try {
      const response = await API.get(
        `/api/rest/v2/rinkelvoicemail/link/${id}`
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  },

  async getVoicemails() {
    try {
      const response = await API.get(`/api/rest/v2/rinkelvoicemail/rinkel`);
      return response;
    } catch (error) {
      console.error(error);
    }
  },

  async updateRouting(id, content) {
    try {
      const response = await API.put(
        `/api/rest/v2/rinkelvoicemail/${id}`,
        content
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async createRouting(data) {
    try {
      const response = await API.post(`/api/rest/v2/rinkelvoicemail`, data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async deleteRouting(id) {
    try {
      const response = await API.delete(`/api/rest/v2/rinkelvoicemail/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
