<template>
  <RinkelVoiceNotes />
</template>

<script>
import RinkelVoiceNotes from "@/components/rinkel/RinkelVoiceNotes";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    RinkelVoiceNotes,
  },
  metaInfo: {
    title: "Integratiepartners digital sign RinkelVoiceNotes",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>
