<template>
  <v-container>
    <v-icon @click="goBackAndRefresh()" style="color: green">home</v-icon>
    <v-btn color="error" @click="goBack()"> Terug </v-btn>
    <v-row>
      <v-col cols="5">
        <form ref="form" @submit.prevent="submitForm">
          <h2>Medewerkers beheren</h2>

          <v-col cols="12">
            <label>Naam medewerker</label>
            <v-text-field dense outlined v-model="team.name"></v-text-field>
          </v-col>

          <v-col cols="12">
            <label> Titel medewerker</label>
            <v-text-field dense outlined v-model="team.title"> </v-text-field>
          </v-col>
          <v-col cols="3">
            <label> Positie </label>
            <v-text-field dense outlined type="number" v-model="team.order">
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <label class="typo__label">Afbeelding toevoegen </label>
            <div
              id="my-strictly-unique-vue-upload-multiple-image"
              style="display: flex; justify-content: left"
            >
              <vue-upload-multiple-image
                @upload-success="uploadImageSuccess"
                @before-remove="beforeRemove"
                @edit-image="editImage"
                :max-image="20"
                :data-images="images"
                idUpload="myIdUpload"
                editUpload="myIdEdit"
                dragText="Sleep afbeeldingen"
                browseText="of klik om te selecteren"
                dropText="Zet uw bestanden hier neer"
                markIsPrimaryText="Instellen als standaard"
                primaryText="standaard"
                popupText="Dit is de standaard afbeelding"
              ></vue-upload-multiple-image>
            </div>
          </v-col>

          <div style="margin-top: 5px">
            <v-btn color="primary" @click="addEmployee()">
              Team aanmaken
            </v-btn>

            <v-btn color="error" text @click="goBack()"> Annuleer </v-btn>
          </div>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueUploadMultipleImage from "vue-upload-multiple-image";
import axios from "axios";
import apiTeam from "../api/apiTeam";
export default {
  components: {
    VueUploadMultipleImage,
  },
  data: () => ({
    days: ["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag"],
    teams: [],
    search: "",
    items: [],
    images: [],
    formDataImages: [],
    edit: false,
    team: {
      name: "",
      title: "",
      order: "",
      workdays: "",
    },
  }),

  async mounted() {
    apiTeam.getTeams().then((data) => {
      this.items = data;
    });
    apiTeam.getAllTeams().then((data) => {
      this.teams = data;
    });
  },
  methods: {
    async goBack() {
      window.location.reload();
    },
    resetForm() {
      this.team.name = "";
      this.team.title = "";
      this.team.order = "";
      this.team.workdays = "";
    },

    async addEmployee() {
      try {
        const payload = {
          ...this.team,
        };
        const { data } = await axios.post(
          "https://digitalsignbackend.waldent.nl/api/rest/v2/team",
          payload
        );
        await Promise.all(
          this.formDataImages.map(async (formData) => {
            console.log("Afbeelding uploaden gestart");
            await axios.post(
              `https://digitalsignbackend.waldent.nl/api/rest/v2/team/${data.id}/image`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          })
        );
        this.$swal.fire("Aangemaakt!", "", "success");
        this.resetForm();
        this.$emit("employee-added");
      } catch (err) {
        console.log(err);
        this.$swal.fire("Controleer of alle velden zijn ingevuld", "", "error");
      }
    },
    uploadImageSuccess(formData, index, fileList) {
      console.log(formData, index, fileList);
      this.$set(this.formDataImages, index, formData);
    },
    beforeRemove(index, done, fileList) {
      console.log("index", index, fileList);
      var r = confirm("remove image");
      if (r == true) {
        done();
      } else {
        this.$Swall.fire("error");
      }
    },
    editImage(formData, index, fileList) {
      console.log("edit data", formData, index, fileList);
      //this.$set(this.formDataImages, index, formData);
    },

    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },

    async goBackAndRefresh() {
      this.$router.go(-1);
    },
  },
};
</script>
