<template>
  <QRGenerator />
</template>

<script>
import QRGenerator from "@/components/dashboard/QRGenerator";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    QRGenerator,
  },
  metaInfo: {
    title: "Integratiepartners digital sign QR code",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>
